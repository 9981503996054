(function() {
	'use strict';
	angular.module('eventvods')
		.controller('ResetController', ['$http','$rootScope', 'API_BASE_URL',
		function($http, $rootScope, API) {
			var vm = this;
			$rootScope.oldGame = $rootScope.game;
			$rootScope.game = 'static';

			vm.submit = function(resetForm){
				if(resetForm.$valid){
					$http.post(API + '/user/reset', vm.data)
						.then(function(){
							vm.complete = true;
						})
				}
			}
		}]);
}());
